import { CollapsableEntity } from '../../types/CollapsableFilterTypes';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../features/state/hooks';
import {
  setCollapsableFilter,
  setGroupCollapsed,
  setGroupExpanded,
} from '../../features/state/slices/homeSlice';

const CollapsableFilter = ({
  arrOfEntities,
  sectionName,
}: {
  arrOfEntities: Array<CollapsableEntity>;
  sectionName: string;
}) => {
  const collapsableFilter = useAppSelector((state) => state.home.value.hideableFilter);
  const dispatch = useAppDispatch();
  const isCollapsed: boolean = !collapsableFilter.expandedGroups.some(
    (groupName) => groupName === sectionName,
  );
  return (
    <div className="aside-filter-section-container">
      <div
        className={
          'expandable-section-group aside-filter-section-name' + (isCollapsed ? ' collapsed' : '')
        }
        onClick={() => {
          const actionToDispatch = isCollapsed ? setGroupExpanded : setGroupCollapsed;
          dispatch(actionToDispatch(sectionName));
        }}>
        {sectionName}
      </div>
      <ul className="aside-filter-options-list">
        {arrOfEntities.map((entity) => (
          <li
            key={entity.id as React.Key}
            className={collapsableFilter.selectedId === entity.id ? 'selected' : ''}
            onClick={() => {
              dispatch(
                setCollapsableFilter({
                  selectedId: entity.id,
                  selectedGroup: sectionName,
                  selectedFilterName: entity.name,
                }),
              );
            }}>
            {entity.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CollapsableFilter;
