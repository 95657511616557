import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import NotificationBell from '../../../assets/icons/notification_bell.svg';

export const SearchResults = () => {
  return (
    <OverlayScrollbarsComponent defer>
      <div className="results-container">
        <div className="results-content">
          <div className="header">
            <div className="results-row">
              <div className="notification">
                <img alt="notification-icon" src={NotificationBell} />
              </div>
              <div className="stage">Stage</div>
              <div className="project-name">Project Name</div>
              <div className="quote-id">Quote ID</div>
              <div className="field-so">Field SO #</div>
              <div className="mfg-so">Manufacturing SO #</div>
            </div>
          </div>
          <div className="content">
            <hr />
            <div className="results-row">
              <div className="notification count">
                <span>1</span>
              </div>
              <div className="stage tag initiation">Initiation</div>
              <div className="project-name">
                Project Name Project Name Project Name Project Name Project Name
              </div>
              <div className="quote-id">2024-08-30-123456789</div>
              <div className="field-so">2024-08-30-123456789</div>
              <div className="mfg-so">2024-08-30-123456789</div>
            </div>
            <hr />
            <div className="results-row">
              <div className="notification count"></div>
              <div className="stage tag manufacturing">Manufacturing</div>
              <div className="project-name">Project Name</div>
              <div className="quote-id">2024-08-30-123456789</div>
              <div className="field-so">2024-08-30-123456789</div>
              <div className="mfg-so">2024-08-30-123456789</div>
            </div>
            <hr />
            <div className="results-row">
              <div className="notification count">
                <span>1</span>
              </div>
              <div className="stage tag operations">Operations</div>
              <div className="project-name">Project Name</div>
              <div className="quote-id">2024-08-30-123456789</div>
              <div className="field-so">2024-08-30-123456789</div>
              <div className="mfg-so">2024-08-30-123456789</div>
            </div>
            <hr />
            <div className="results-row">
              <div className="notification count">
                <span>2</span>
              </div>
              <div className="stage tag technology">Technology</div>
              <div className="project-name">Project Name</div>
              <div className="quote-id">2024-08-30-123456789</div>
              <div className="field-so">2024-08-30-123456789</div>
              <div className="mfg-so">2024-08-30-123456789</div>
            </div>
            <hr />
            <div className="results-row">
              <div className="notification count">
                <span>27</span>
              </div>
              <div className="stage tag canceled">Canceled</div>
              <div className="project-name">Project Name</div>
              <div className="quote-id">2024-08-30-123456789</div>
              <div className="field-so">2024-08-30-123456789</div>
              <div className="mfg-so">2024-08-30-123456789</div>
            </div>
          </div>
          <hr />
          <div className="pagination">
            <div className="pagination-option selected">1</div>
            <div className="pagination-option">2</div>
            <div className="pagination-option">3</div>
            <div className="pagination-option">4</div>
            <div className="pagination-option next">Next &gt;&gt;</div>
          </div>
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
};
