export const CategorySearch = () => {
  const searchButtons = (screen: string) => (
    <div className={`search-buttons ${screen}`}>
      <div className="btn">Clear Filters</div>
      <div className="btn enabled">Show Results</div>
    </div>
  );

  return (
    <div className="filters-container">
      <div className="header">
        <div className="title">
          <h1>Category Search</h1>
          <p>
            Quickly find the project you are looking for by selecting from the predefined options
            below.
          </p>
        </div>
        {searchButtons('desktop')}
      </div>
      <hr />
      <div className="filters">
        <div className="input-filters">
          <div className="filter-text">
            <h3>Project Title</h3>
            <input type="text" placeholder="Enter Project Title" />
          </div>
          <div className="filter-text">
            <h3>Project Id</h3>
            <input type="text" placeholder="Enter Project Id" />
          </div>
          <div className="filter-text">
            <h3>Region</h3>
            <input type="text" placeholder="Enter Region" />
          </div>
          <div className="filter-text">
            <h3>Customer Name</h3>
            <input type="text" placeholder="Enter Customer Name" />
          </div>
          <div className="filter-text">
            <h3>Quote ID</h3>
            <input type="text" placeholder="Enter Quote ID" />
          </div>
          <div className="filter-text">
            <h3>RTA Number</h3>
            <input type="text" placeholder="Enter RTA Number" />
          </div>
          <div className="filter-text">
            <h3>Field Sales Order</h3>
            <input type="text" placeholder="Enter Field Sales Order" />
          </div>
          <div className="filter-text">
            <h3>Manufacturing Sales Order</h3>
            <input type="text" placeholder="Enter Manufacturing Sales Order" />
          </div>
        </div>
        <div className="toggleable-filters">
          <div className="creation-date">
            <h3>Creation Date</h3>
            <div className="items">
              <div className="toggle selected">24 Hr</div>
              <div className="toggle selected">WTD</div>
              <div className="toggle selected">MTD</div>
              <div className="toggle selected">6 Mth</div>
              <div className="toggle selected">YTD</div>
              <div className="toggle selected">1 Yr</div>
              <div className="toggle selected">All</div>
            </div>
          </div>
          <div className="status">
            <h3>Status</h3>
            <div className="items">
              <div className="toggle initiation selected">Initiation</div>
              <div className="toggle technology selected">Technology</div>
              <div className="toggle manufacturing selected">Manufacturing</div>
              <div className="toggle operations selected">Operations</div>
              <div className="toggle canceled selected">Canceled</div>
              <div className="toggle all selected">All</div>
            </div>
          </div>
        </div>
      </div>
      {searchButtons('mobile')}
    </div>
  );
};
