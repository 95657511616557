import React from 'react';
import { useWindowSize } from '../../utils/hooks/useWindowSize';

const AiHelper = () => {
  const { width } = useWindowSize();
  const aiHelperText = width < 1125 && width >= 920 ? '' : 'What can I answer for you?';
  return (
    <button
      onClick={() => {
        //TO-DO
      }}
      className="ai-helper">
      {aiHelperText}
    </button>
  );
};

export default AiHelper;
