import { Contact } from '../../types/ContactTypes';
import { getAlphabetPosition, getInitials } from '../../utils/naming';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

interface ContactListProps {
  contacts: Array<Contact>;
}

const ContactsList = ({ contacts }: ContactListProps) => {
  return (
    <OverlayScrollbarsComponent defer>
      <div className="contacts-container">
        <h6 className="contacts-list-header">Contacts</h6>

        <ul className="contacts-list">
          {contacts.map((contact, i) => {
            const alphabetClass = `alphabet-${getAlphabetPosition(contact.firstName.charAt(0))}`;
            const formattedPhone = contact.phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            return (
              <li key={`contact-card-${i}}`} className="contact-list-item">
                <span className={`contact-card-user-initials ${alphabetClass}`}>
                  {getInitials(`${contact.firstName} ${contact.lastName}`)}
                </span>
                <div className="contact-card">
                  <span className="contact-name">{`${contact.firstName} ${contact.lastName}`}</span>
                  <span className="contact-phone">{formattedPhone}</span>
                  <span className="contact-email">{contact.email}</span>
                  <span className="contact-department">{contact.departmentName}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </OverlayScrollbarsComponent>
  );
};

export default ContactsList;
