import { Link, NavLink, matchPath, useLocation } from 'react-router-dom';
import { routeName as ProjectRoute } from '../../pages/Project';
import { useState } from 'react';
import { routeName as ProductCenterRoute } from '../../pages/ProductCenter';
import { routeName as SearchRoute } from '../../pages/Search';

import xImage from '../../assets/icons/x.svg';
import eCompletionsLogo from '../../assets/images/e-completions-logo.svg';
import { useOktaAuth } from '@okta/okta-react';
import { useAppSelector } from '../../features/state/hooks';
import AiHelper from '../common/AiHelper';
import { useWindowSize } from '../../utils/hooks/useWindowSize';

export const NavigationBar = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [logoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const currentUser = useAppSelector((state) => state.currentUser.value);
  const userInitials = currentUser?.initials || 'NA';
  const { isMobile } = useWindowSize();

  const handleLogoutPopup = () => {
    oktaAuth.signOut();
  };

  const setNavLinkClasses = (paths: string[]) =>
    paths.some((path) => matchPath(path, pathname)) ? 'navbar-button active' : 'navbar-button';

  return (
    <>
      <div className="navbar">
        <div className="burger-menu-toggle" onClick={() => setMenuOpen(true)}>
          <span />
          <span />
          <span />
        </div>
        <Link className="navbar-brand" to={authState?.isAuthenticated ? '/home' : '/'}>
          <img src={eCompletionsLogo} alt="HalTrax" />
        </Link>

        <div className={`menu ${menuOpen && 'open'}`}>
          <div className="navigation-menu-title-container">
            <span className="navigation-menu-title">Navigation</span>
            <div className="close-menu" onClick={() => setMenuOpen(false)}>
              <img src={xImage} alt={'X'} />
            </div>
          </div>
          <NavLink
            className={setNavLinkClasses(['/', '/home', `/${ProjectRoute}/:id`])}
            to="/home"
            onClick={() => setMenuOpen(false)}>
            Project Center
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? 'navbar-button active' : 'navbar-button')}
            to={ProductCenterRoute}
            onClick={() => setMenuOpen(false)}>
            Product Center
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? 'navbar-button active' : 'navbar-button')}
            to="/notifications"
            onClick={() => setMenuOpen(false)}>
            Notifications
          </NavLink>
          <NavLink
            className={({ isActive }) => (isActive ? 'navbar-button active' : 'navbar-button')}
            to={SearchRoute}
            onClick={() => setMenuOpen(false)}>
            Search
          </NavLink>
        </div>
        <div className="profile-menu">
          <div className={`logout-menu ${logoutPopupOpen && 'open'}`} onClick={handleLogoutPopup}>
            <div>Logout</div>
          </div>
          <AiHelper />
          <div className="profile" onClick={() => setLogoutPopupOpen(!logoutPopupOpen)}>
            {userInitials}
          </div>
        </div>
      </div>
    </>
  );
};
