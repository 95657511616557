import {
  CollapsableGroupListData,
  CollapsableGroupData,
  CollapsableEntity,
} from '../../types/CollapsableFilterTypes';
import CollapsableFilterGroup from './CollapsableFilterGroup';
import NonCollapsableFilterPseudoGroup from './NonCollapsableFilterPseudoGroup';
import { useWindowSize } from '../../utils/hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../features/state/hooks';
import { closeHideableFilters } from '../../features/state/slices/homeSlice';
import SearchAndSortPanel from '../common/SearchAndSortPanel';

const CollapsableFilterGroupList = ({
  groupListData,
  onSortChange,
  onSearchChange,
}: {
  groupListData: CollapsableGroupListData;
  onSortChange: (sortOption: { config: { reverseOrder: any } }) => void;
  onSearchChange: (searchValue: string) => void;
}) => {
  const { isMobile } = useWindowSize();
  const { filtersOpen } = useAppSelector((state) => state.home.value.hideableFilter);
  const dispatch = useAppDispatch();

  const componentOutput = (
    <nav className="aside-collapsable-filter-group-list">
      {isMobile && (
        <>
          <button
            className="aside-filters-close-button"
            onClick={() => dispatch(closeHideableFilters())}
          />
          <div className="aside-filter-section-group">
            <span className="aside-filter-section-group-header mobile-filters-group-header">
              Filters
            </span>
            <SearchAndSortPanel onSearchChange={onSearchChange} onSortChange={onSortChange} />
          </div>
        </>
      )}

      {Object.entries(groupListData).map(([groupName, groupData], idx) =>
        Array.isArray(groupData) ? (
          <NonCollapsableFilterPseudoGroup
            filterGroupData={groupData as Array<CollapsableEntity>}
            listHeader={groupName}
            key={`collapsable-filter-group-list${idx}`}
          />
        ) : (
          <CollapsableFilterGroup
            filterGroupData={groupData as CollapsableGroupData}
            listHeader={groupName}
            key={`collapsable-filter-group${idx}`}
          />
        ),
      )}
    </nav>
  );

  return isMobile ? (
    <>
      <div className={`aside-filters-mobile-container${filtersOpen ? '' : ' closed'}`}>
        {componentOutput}
      </div>
    </>
  ) : (
    componentOutput
  );
};

export default CollapsableFilterGroupList;
