import { useEffect, useState } from 'react';
import ChartHeader from './ChartHeader';
import { SubHeaderItemType } from './ProjectDetailsHeader';
import ContainerWithNotifications from '../../common/ContainerWithNotifications';
import ProjectStages from './ProjectStages';
import { COMPLETION_DATE_STATUS } from '../../../constants/salesOrder';
import { removeLeadingZeroes } from '../../../utils/numbers';
import { useAppSelector } from '../../../features/state/hooks';
import {
  ProjectStage,
  ProjectStageChartItem,
  ProjectSubHeaderItem,
} from '../../../types/ProjectTypes';
import { IProject } from '../../../models/project';
import { ContainerWithAsidePanel } from '../../common/ContainerWithAsidePanel';
import { useNavigate } from 'react-router-dom';
import ProjectDetailsHeader from './ProjectDetailsHeader';
import { ProjectDetailsMap } from './ProjectDetailsMap';
import { useWindowSize } from '../../../utils/hooks/useWindowSize';
import CaretIcon from '../../../assets/icons/button_caret_red.svg';
import { PushPin } from '../../../components/pages/ProjectDetails/ProjectDetailsMap';

const projectStagesIds = {
  businessDevelopment: 'businessDevelopment',
  technology: 'technology',
  manufacturing: 'manufacturing',
  operations: 'operations',
};

interface ProjectDetailsProps {
  goToSummary: (soNumber: string) => void;
}

const ProjectDetails = ({ goToSummary }: ProjectDetailsProps) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const project = useAppSelector((state) => state.project.value);
  const projectNotifications = useAppSelector((state) => state.notifications.value.project);

  const [projectStages, setProjectStages] = useState<ProjectStage[]>(generateStepItems(project));

  const [chartItems, setChartItems] = useState<ProjectStageChartItem[]>(
    generateChartItems(projectStages),
  );

  const [subHeaderItems, setSubHeaderItems] = useState(generateSubHeaderItems(project, chartItems));

  useEffect(() => {
    setProjectStages(generateStepItems(project));
  }, [project]);

  useEffect(() => {
    setChartItems(generateChartItems(projectStages));
  }, [projectStages]);

  useEffect(() => {
    setSubHeaderItems(generateSubHeaderItems(project, chartItems));
  }, [chartItems, project]);

  const pushPins = projectStages.find((stage) => stage.id == projectStagesIds.operations)?.pushPins;

  return (
    <>
      <ContainerWithAsidePanel
        content={
          <ChartHeader
            projectTitle={project.project.name}
            subHeaderComponent={
              <ProjectDetailsHeader
                project={project}
                subHeaderItems={subHeaderItems}
                stagesComplete={chartItems.every((item) => item.completed === item.total)}
                cancelled={project.project.isCancelled}
              />
            }
            chartItems={chartItems}
          />
        }
        asideContent={
          <>
            {isMobile && projectNotifications.length > 0 && (
              <div
                className="project-notification-banner"
                onClick={() => navigate('/notifications')}>
                <div>
                  <span id="count">{projectNotifications.length}</span>
                  Notifications
                </div>
                <img alt="caret" src={CaretIcon} />
              </div>
            )}
            {!!pushPins?.length && (
              <ProjectDetailsMap
                pushPins={[
                  {
                    center: { latitude: 27.98785, longitude: 86.925026 },
                    options: { title: 'Mount Everest' },
                  },
                  {
                    center: { latitude: 27.9881, longitude: 86.925 },
                    options: { title: 'Mount Everest Base Camp' },
                  },
                ]}
              />
            )}
          </>
        }
        hideAsideOnMobile={false}
      />
      <h4 className="project-stages-title">Stages</h4>
      <ContainerWithNotifications
        content={
          <ProjectStages stages={projectStages} chartItems={chartItems} goToSummary={goToSummary} />
        }
      />
    </>
  );
};

// #region Generator functions

const parseDate = (date: string) => {
  if (date === COMPLETION_DATE_STATUS.TBD) return COMPLETION_DATE_STATUS.TBD;
  return date ? new Date(date).toLocaleDateString('en-US') : COMPLETION_DATE_STATUS.TBD;
};

const generatePushPin = (pinTitle: string, siteAddress?: string): PushPin | null => {
  if (siteAddress) {
    const regex =
      /Surface Latitude:\s*([-+]?\d*\.\d+|[-+]?\d+)\s*'\s*Surface Longitude:\s*([-+]?\d*\.\d+|[-+]?\d+)/;

    const match = siteAddress.match(regex);
    if (match) {
      const latitude = match[1];
      const longitude = match[2];
      return {
        center: {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
        },
        options: {
          title: pinTitle,
        },
      };
    }
  }
  return null;
};

const generateSubHeaderItems = (
  project: IProject,
  chartItems: ProjectStageChartItem[],
): ProjectSubHeaderItem[] => [
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Project ID',
    value: project.project.projectId,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Customer Name',
    value: project.project.customerName,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Region',
    value: project.project.region,
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Wells',
    value: project.project.wellInfo.count.toString(),
  },
  {
    type: SubHeaderItemType.INFO.key,
    title: 'Location',
    value: project.project.wellInfo.offShore ? 'OffShore' : 'OnShore',
  },

  {
    type: SubHeaderItemType.JOB_STAGE.key,
    title: SubHeaderItemType.JOB_STAGE.title,
    value: chartItems
      .filter((item) => {
        const notComplete = item.completed / item.total < 1;
        const started = item.completed > 0;
        return (notComplete && started) || (notComplete && item.atLeastOneFieldStartedOnAnyItem);
      })
      .map((item) => item.title),
  },
];

/*
  Generates the project stages data

  Step items are generated based on the project data. Each step item contains the following properties:
  - id: The step id
  - isMilestone: Indicates if the step is a milestone, the design is different for milestones
  - title: The step title
  - subtitle: The step subtitle
  - itemHeaders: The headers for the step items
    - size: The total amount of space the column will take, this also affects the size of each row's column size. 
            The sum of these need to add up to 95, as the first column (status symbol) takes 5
  - itemValues: The values for the step items
  - rowProgress: A method that receives an item array, where the indices match the columns in order, and returns the progress status of the row
  - itemCompletedCount: The count of completed items
  - atLeastOneFieldStartedOnAnyItem: Indicates if at least one field has been started on any item
*/
const generateStepItems = (project: IProject): ProjectStage[] => [
  {
    id: projectStagesIds.businessDevelopment,
    isMilestone: false,
    title: 'Initiation',
    subtitle: 'Details about your Quotes',
    itemHeaders: [
      { title: 'Quote ID', size: 25, boldValue: true },
      { title: 'Submitted to Customer Date', size: 25, countForPercentage: false },
      { title: 'Quote Validity Date', size: 25, countForPercentage: false },
      { title: 'LOI Received Date', size: 20, countForPercentage: true },
    ],
    itemValues: project.stages.businessDevelopment.map((item) => [
      item.quoteId,
      parseDate(item.submittedToCustomerDate),
      parseDate(item.quoteValidityDate),
      parseDate(item.loiReceivedDate),
    ]),
    rowProgress: (item) => {
      const submittedDate = item[1];
      const targetCompletedDate = item[2];
      const actualCompletedDate = item[3];

      if (actualCompletedDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const actualCompletedDateInTheFuture = new Date(actualCompletedDate) > new Date();
        return actualCompletedDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    itemCompletedCount: project.stages.businessDevelopment.filter(
      (item) => parseDate(item.loiReceivedDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem:
      project.stages.businessDevelopment.findIndex(
        (item) =>
          parseDate(item.submittedToCustomerDate) !== COMPLETION_DATE_STATUS.TBD ||
          parseDate(item.quoteValidityDate) !== COMPLETION_DATE_STATUS.TBD ||
          parseDate(item.loiReceivedDate) !== COMPLETION_DATE_STATUS.TBD,
      ) !== -1,
    percentageCompletedItems: project.stages.businessDevelopment.map(
      (item) => item.percentageCompleted,
    ),
  },
  {
    id: projectStagesIds.technology,
    isMilestone: false,
    title: 'Technology',
    subtitle: 'Details about your RTAs',
    itemHeaders: [
      { title: 'RTA Number', size: 25, boldValue: true },
      { title: 'Submitted', size: 25, countForPercentage: true },
      { title: 'Target Completed Date', size: 25, countForPercentage: false },
      { title: 'Actual Completed Date', size: 20, countForPercentage: true },
    ],
    itemValues: project.stages.technology.map((item) => [
      item.rtaNumber,
      parseDate(item.submittedDate),
      parseDate(item.targetCompletedDate),
      parseDate(item.actualCompletedDate),
    ]),
    rowProgress: (item) => {
      const submittedDate = item[1];
      const targetCompletedDate = item[2];
      const actualCompletedDate = item[3];

      if (actualCompletedDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const actualCompletedDateInTheFuture = new Date(actualCompletedDate) > new Date();
        return actualCompletedDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    itemCompletedCount: project.stages.technology.filter(
      (item) => parseDate(item.actualCompletedDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem:
      project.stages.technology.findIndex(
        (item) =>
          parseDate(item.submittedDate) !== COMPLETION_DATE_STATUS.TBD ||
          parseDate(item.targetCompletedDate) !== COMPLETION_DATE_STATUS.TBD ||
          parseDate(item.actualCompletedDate) !== COMPLETION_DATE_STATUS.TBD,
      ) !== -1,
    percentageCompletedItems: project.stages.technology.map((item) => item.percentageCompleted),
  },
  {
    id: projectStagesIds.manufacturing,
    isMilestone: false,
    title: 'Manufacturing',
    subtitle: 'Details about your Manufacturing Sales Orders',
    itemHeaders: [
      { title: 'SO', size: 20, boldValue: true },
      { title: 'Line Item', size: 20 },
      { title: 'Material Number', size: 20 },
      { title: 'Target Ex Works', size: 20, countForPercentage: true },
      { title: 'Projected Complete Date', size: 20, countForPercentage: true },
    ],
    itemValues: project.stages.manufacturingData.map((item) => [
      item.soNumber,
      item.soItemNumber,
      removeLeadingZeroes(item.materialNumber),
      parseDate(item.targetExWorksCompleteDate),
      parseDate(item.projectedCompleteDate),
    ]),
    rowProgress: (item) => {
      const targetExWorksCompleteDate = item[3];
      const projectedCompleteDate = item[4];

      if (projectedCompleteDate === COMPLETION_DATE_STATUS.TBD) {
        return COMPLETION_DATE_STATUS.TBD;
      } else {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        const projectedCompleteDateInTheFuture = new Date(projectedCompleteDate) > new Date();
        return projectedCompleteDateInTheFuture
          ? COMPLETION_DATE_STATUS.IN_PROGRESS
          : COMPLETION_DATE_STATUS.COMPLETED;
      }
    },
    itemCompletedCount: project.stages.manufacturingData.filter(
      (item) =>
        parseDate(item.targetExWorksCompleteDate) !== COMPLETION_DATE_STATUS.TBD &&
        parseDate(item.projectedCompleteDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem:
      project.stages.manufacturingData.findIndex(
        (item) =>
          parseDate(item.targetExWorksCompleteDate) !== COMPLETION_DATE_STATUS.TBD ||
          parseDate(item.projectedCompleteDate) !== COMPLETION_DATE_STATUS.TBD,
      ) !== -1,
    percentageCompletedItems: project.stages.manufacturingData.map(
      (item) => item.percentageCompleted,
    ),
  },
  {
    id: projectStagesIds.operations,
    isMilestone: false,
    title: 'Operations',
    subtitle: 'Details about your Jobs',
    itemHeaders: [
      { title: 'SO', size: 15, boldValue: true, link: true },
      { title: 'DOS', size: 10 },
      { title: 'Job ID', size: 15 },
      { title: 'Est. Job Timing', size: 15 },
      { title: 'Actual on Location Date', size: 20, countForPercentage: true },
      { title: 'Job Close Date', size: 20, countForPercentage: true },
    ],
    itemValues: project.stages.operation.map((item) => [
      item.soNumber,
      item.dosNumber,
      item.jobId,
      item.jobTiming,
      parseDate(item.actualOnLocationDate),
      parseDate(item.jobCloseDate),
    ]),
    rowProgress: (item) => {
      const actualOnLocationDate = item[4];
      const jobCloseDate = item[5];
      const actualDate = new Date();

      if (
        actualOnLocationDate === COMPLETION_DATE_STATUS.TBD &&
        jobCloseDate === COMPLETION_DATE_STATUS.TBD
      ) {
        return COMPLETION_DATE_STATUS.TBD;
      } else if (
        new Date(actualOnLocationDate) < actualDate &&
        new Date(jobCloseDate) < actualDate
      ) {
        // TODO: Missing to check if/when COMPLETION_DATE_STATUS.CANCELLED
        return COMPLETION_DATE_STATUS.COMPLETED;
      } else {
        return COMPLETION_DATE_STATUS.IN_PROGRESS;
      }
    },
    pushPins: project.stages.operation
      .map((stage) => generatePushPin(stage.jobId, stage.siteAddress))
      .filter((pin) => !!pin),
    itemCompletedCount: project.stages.operation.filter(
      (item) => parseDate(item.actualOnLocationDate) !== COMPLETION_DATE_STATUS.TBD,
    ).length,
    atLeastOneFieldStartedOnAnyItem:
      project.stages.operation.findIndex(
        (item) => parseDate(item.actualOnLocationDate) !== COMPLETION_DATE_STATUS.TBD,
      ) !== -1,
    percentageCompletedItems: project.stages.operation.map((item) => item.percentageCompleted),
  },
  {
    isMilestone: true,
    milestones: project.stages.dispatchedToField,
  },
];

const calculateCompletedCount = (projectStages: ProjectStage[], jobStageId: string) => {
  const stageItems = projectStages.find((item) => item.id === jobStageId);
  return stageItems!.itemValues!.reduce((acc, item) => {
    const rowProgress = stageItems!.rowProgress!(item);
    const progress =
      rowProgress === COMPLETION_DATE_STATUS.COMPLETED
        ? 1
        : rowProgress === COMPLETION_DATE_STATUS.IN_PROGRESS
        ? 0.5
        : 0;
    return acc + progress;
  }, 0);
};

const calculateStagePercentage = (projectStages: ProjectStage[], jobStageId: string) => {
  const stageItems = projectStages.find((item) => item.id === jobStageId);
  return stageItems?.percentageCompletedItems?.length
    ? Math.ceil(
        stageItems.percentageCompletedItems.reduce(
          (currentVal, previousVal) => currentVal + previousVal,
          0,
        ) / stageItems.percentageCompletedItems.length,
      )
    : 0;
};

const generateChartItems = (projectStages: ProjectStage[]): ProjectStageChartItem[] => [
  {
    id: projectStagesIds.businessDevelopment,
    title: 'Initation',
    completed: calculateCompletedCount(projectStages, projectStagesIds.businessDevelopment),
    total: projectStages.find((item) => item.id === projectStagesIds.businessDevelopment)!
      .itemValues!.length,
    atLeastOneFieldStartedOnAnyItem:
      projectStages.find((item) => item.id === projectStagesIds.businessDevelopment)!
        .atLeastOneFieldStartedOnAnyItem || false,
    percentageCompleted: calculateStagePercentage(
      projectStages,
      projectStagesIds.businessDevelopment,
    ),
  },
  {
    id: projectStagesIds.technology,
    title: 'Technology',
    completed: calculateCompletedCount(projectStages, projectStagesIds.technology),
    total: projectStages.find((item) => item.id === projectStagesIds.technology)!.itemValues!
      .length,
    atLeastOneFieldStartedOnAnyItem:
      projectStages.find((item) => item.id === projectStagesIds.technology)!
        .atLeastOneFieldStartedOnAnyItem || false,
    percentageCompleted: calculateStagePercentage(projectStages, projectStagesIds.technology),
  },
  {
    id: projectStagesIds.manufacturing,
    title: 'Manufacturing',
    completed: calculateCompletedCount(projectStages, projectStagesIds.manufacturing),
    total: projectStages.find((item) => item.id === projectStagesIds.manufacturing)!.itemValues!
      .length,
    atLeastOneFieldStartedOnAnyItem:
      projectStages.find((item) => item.id === projectStagesIds.manufacturing)!
        .atLeastOneFieldStartedOnAnyItem || false,
    percentageCompleted: calculateStagePercentage(projectStages, projectStagesIds.manufacturing),
  },
  {
    id: projectStagesIds.operations,
    title: 'Operations',
    completed: calculateCompletedCount(projectStages, projectStagesIds.operations),
    total: projectStages.find((item) => item.id === projectStagesIds.operations)!.itemValues!
      .length,
    atLeastOneFieldStartedOnAnyItem:
      projectStages.find((item) => item.id === projectStagesIds.operations)!
        .atLeastOneFieldStartedOnAnyItem || false,
    percentageCompleted: calculateStagePercentage(projectStages, projectStagesIds.operations),
  },
];
// #endregion Generator functions

export default ProjectDetails;
