import { useEffect } from 'react';
import {
  setLoading,
  setProjectsPagination,
  clearProjects,
  appendProjects,
  setSummaryHeader,
  setProjectsConfigSkip,
  setProjectsConfigReverseOrder,
  setProjectsConfigSearchValue,
  setProjectsConfigSortOption,
  setProjectsShownAmount,
} from '../features/state/slices/homeSlice';
import { setBackPressed } from '../features/state/slices/configSlice';
import { HOME_PROJECTS_LOADING_STEP } from '../constants/home';
import { useNavigate } from 'react-router-dom';
import { getProjects } from '../api/endpoints/projects';
import { useApi } from '../api/ApiContext';
import { useAppDispatch, useAppSelector } from '../features/state/hooks';
import ProjectCardsSection from '../components/common/ProjectCardsSection';
import CollapsableFilterGroupList from '../components/common/CollapsableFilterGroupList';
import { ProjectItem, ProjectProgress } from '../types/ProjectTypes';
import { ProjectStatus, ProjectType } from '../constants/projectItem';
import { CollapsableGroupListData } from '../types/CollapsableFilterTypes';

const msToTriggerSearch = 1000;

const Home = () => {
  const { apiInstance } = useApi();
  let clearTimeoutId: NodeJS.Timeout;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { summaryHeaderInfo } = useAppSelector((state) => state.home.value);
  const { skipProjectAmt, sortOption, reverseOrder, searchValue, projectsShownAmount } =
    useAppSelector((state) => state.home.value.projectsConfig);
  const { projects, projectsPagination, loading } = useAppSelector((state) => state.home.value);
  const backPressed = useAppSelector((state) => state.config.value.backPressed);

  useEffect(() => {
    if (backPressed) {
      dispatch(setBackPressed(false));
      return;
    }

    dispatch(setLoading(true));
    getProjects(
      apiInstance,
      HOME_PROJECTS_LOADING_STEP,
      skipProjectAmt,
      sortOption.config.sortType,
      reverseOrder,
      searchValue,
    )
      .then((response) => {
        dispatch(
          setProjectsPagination({
            resultsStart: response.responseAsJson.resultsStart,
            resultsEnd: response.responseAsJson.resultsEnd,
            totalResults: response.responseAsJson.totalResults,
          }),
        );
        const newProjects: ProjectItem[] = response.responseAsJson.projects.map((project: any) => {
          return {
            projectId: project.projectId,
            projectProgress: {
              initiation: project.progressStages.quoteProgress,
              technology: project.progressStages.technologyProgress,
              manufacturing: project.progressStages.manufacturingProgress,
              operations: project.progressStages.operationsProgress,
            },
            quoteCount: project.quoteCount,
            projectName: project.projectname.substring(0, 25), // Temporary substring to avoid overflow in the card. We're switching out of this design, good for now
            projectStatus: ProjectStatus.operations,
            wellsAmount: project.wellInfo?.count || 0,
            projectType: ProjectType.onShore,
          };
        });

        dispatch(appendProjects(newProjects));

        const newTotalProjects = response.responseAsJson.totalResults;
        dispatch(
          setSummaryHeader({
            totalProjects: newTotalProjects,
            activeTotalJobs: `*12`,
          }),
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      })
      .catch((error) => {});
  }, [
    dispatch,
    navigate,
    skipProjectAmt,
    reverseOrder,
    sortOption,
    searchValue,
    backPressed,
    summaryHeaderInfo.totalProjects,
    apiInstance,
    projectsShownAmount,
  ]);

  /*const onPageChange = (direction: number) => {
    if (direction === HOME_PAGINATION.PREVIOUS) {
      dispatch(setProjectsConfigSkip(skipProjectAmt - HOME_PROJECTS_PER_PAGE));
    } else if (direction === HOME_PAGINATION.NEXT) {
      dispatch(setProjectsConfigSkip(skipProjectAmt + HOME_PROJECTS_PER_PAGE));
    }
  };*/

  const onSortChange = (sortOption: { config: { reverseOrder: any } }) => {
    dispatch(clearProjects());
    dispatch(setProjectsShownAmount(HOME_PROJECTS_LOADING_STEP));
    dispatch(setProjectsConfigSortOption(sortOption));
    dispatch(setProjectsConfigReverseOrder(sortOption.config.reverseOrder));
    dispatch(setProjectsConfigSkip(0));
  };

  const onSearchChange = (searchValue: any) => {
    // Debounce search
    if (clearTimeoutId) {
      clearTimeout(clearTimeoutId);
    }
    clearTimeoutId = setTimeout(() => {
      dispatch(setProjectsShownAmount(HOME_PROJECTS_LOADING_STEP));
      dispatch(clearProjects());
      dispatch(setProjectsConfigSearchValue(searchValue));
      dispatch(setProjectsConfigSkip(0));
    }, msToTriggerSearch);
  };

  const filterTestObj: CollapsableGroupListData = {
    Region: {
      'Latin America': [
        {
          id: '1',
          name: 'Mexico',
        },
        {
          id: '2',
          name: 'Ecuador',
        },
        {
          id: '3',
          name: 'Argentina',
        },
      ],
      'Gulf Coast US': [
        {
          id: '4',
          name: 'Ecuador',
        },
      ],
      'Middle East N. Africa': [
        {
          id: '5',
          name: 'Ecuador',
        },
      ],
    },
    'Past Projects': [
      { id: '10010432', name: '2024' },
      { id: '10010433', name: '2023' },
      { id: '10010434', name: '2022' },
    ],
  };

  const firstPage = projectsPagination.resultsStart === 1;
  const lastPage = projectsPagination.resultsEnd === projectsPagination.totalResults;
  const emptyResults = projectsPagination.totalResults === 0;
  const disablePrevious = firstPage || loading || emptyResults;
  const disableNext = lastPage || loading || emptyResults;

  const totalResults = projectsPagination.totalResults;
  const resultsEnd = projectsPagination.resultsEnd;
  const resultsStart =
    projectsPagination.resultsStart > totalResults ? totalResults : projectsPagination.resultsStart;
  return (
    <div className="main-container home-container">
      <div className="home-content-container">
        <CollapsableFilterGroupList
          onSearchChange={onSearchChange}
          onSortChange={onSortChange}
          groupListData={filterTestObj}
        />
        <div className="projects-container">
          <div className="projects-banner">
            <span className="projects-banner-label">
              Complete formation isolation and fluid-loss control
            </span>
            <a
              className="projects-banner-button"
              href="https://search.ecompletions.ienergy.halliburton.com/"
              target="_blank">
              Shop Now
            </a>
          </div>
          <ProjectCardsSection
            onSearchChange={onSearchChange}
            onSortChange={onSortChange}
            projectCards={projects}
            showActive={true}
          />

          {/*<div className="home-pagination-container">
            <div className="pagination">
              <button
                className="pagination-btn pagination-btn-left"
                disabled={disablePrevious}
                onClick={() => {
                  onPageChange(HOME_PAGINATION.PREVIOUS);
                }}>
                <span className="material-icons">Previous</span>
              </button> 
              <span className="pagination-text">
                {`Showing ${resultsStart}-${resultsEnd} of ${totalResults} projects`}
              </span>
               <button
                className="pagination-btn pagination-btn-right"
                disabled={disableNext}
                onClick={() => {
                  onPageChange(HOME_PAGINATION.NEXT);
                }}>
                <span className="material-icons">Next</span>
              </button> 
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Home;
