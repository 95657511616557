/**
 * Global Config
 */

/**
 * Config Keys
 */
const configKey: string = '__site_config';

interface SiteConfig {
  API_BASE_URL: string;
  CONTACT_US_URL: string;
  OKTA_CLIENTID: string;
  OKTA_ISSUER: string;
  BING_MAPS_APIKEY: string;
}

(function () {
  if (typeof window !== 'undefined' && typeof window[configKey as any] === 'undefined') {
    // write the configuration to a global location in a way that is not editable.

    Object.defineProperty(window, configKey, {
      value: Object.freeze<SiteConfig>({
        API_BASE_URL: '%%API_BASE_URL%%',
        CONTACT_US_URL:
          'https://teams.microsoft.com/l/app/f6405520-7907-4464-8f6e-9889e2fb7d8f?templateInstanceId=ad83a41e-b807-4895-b560-6cc2f28afe9b&environment=18a32d73-e11a-472c-b0ad-6aa712bcf920',
        OKTA_CLIENTID: '%%OKTA_CLIENTID%%',
        OKTA_ISSUER: '%%OKTA_ISSUER%%',
        BING_MAPS_APIKEY: '%%BING_MAPS_APIKEY%%',
      }),

      configurable: false,
      enumerable: false,
      writable: false,
    });
  }
})();

/**
 * Helpers for accessing site configuration values.
 */

/**
 * Pattern for detecting placeholder values.
 * @type {RegEx}
 */
const placeholderPattern = /^%%[^%]+%%$/;

/**
 * Returns the specified value from the current configuration.
 *
 * @param {String} key Key for the value to be returned.
 * @param {Any} defaultValue Value to applied if the placeholder is detected (optional).
 *
 * @returns {Any} Configuration value.
 */
export function getConfig(key: string, defaultValue = undefined) {
  let value = (window[configKey as any] as Record<string, any>)[key];

  if (value === `%%${key}%%`) return process.env[`REACT_APP_${key}`];

  if (
    !value ||
    ((typeof value === 'string' || value instanceof String) &&
      placeholderPattern.test(value as string))
  ) {
    value = defaultValue;
  }

  return value;
}

/**
 * API base url.
 */
export const apiBaseUrl: string = getConfig('API_BASE_URL');

/**
 * Contact Us URL
 * This is a hardcoded value.
 */
export const contactUsUrl: string = getConfig('CONTACT_US_URL');

/**
 * Okta client identifier.
 * @type {String}
 */
export const oktaClientId: string = getConfig('OKTA_CLIENTID');

/**
 * Valid Okta issuer.
 * @type {String}
 */
export const oktaIssuer: string = getConfig('OKTA_ISSUER');

/**
 * Bing Maps API key.
 * @type {String}
 */
export const bingMapsApiKey: string = getConfig('BING_MAPS_APIKEY');
