/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import Summary from '../components/pages/Home/Summary';
import ProjectDetails from '../components/pages/ProjectDetails';
import { useNavigate, useParams } from 'react-router-dom';
import { getProjectStages } from '../api/endpoints/projects';
import {
  setStages,
  reset,
  setProject,
  setProjectSummary,
} from '../features/state/slices/projectSlice';
import { getProjectSummaryForSo } from '../api/endpoints/salesOrder';
import { useApi } from '../api/ApiContext';
import { useAppDispatch } from '../features/state/hooks';
import { useOktaAuth } from '@okta/okta-react';

export const routeName = 'project';

const Project = () => {
  const { apiInstance } = useApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { id } = useParams();
  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const onTabClick = (tabIdx: number) => {
    setActiveTabIdx(tabIdx);
  };

  const goToJobProgress = () => {
    setActiveTabIdx(0);
  };

  const goToSummary = (soNumber: string) => {
    getProjectSummaryForSo(apiInstance, soNumber)
      .then((res) => {
        const projectSummary = res.responseAsJson;
        dispatch(setProjectSummary(projectSummary));
      })
      .catch((e) => {});
    setActiveTabIdx(1);
  };

  const _tabTitles = ['Job Progress', 'Summary'];
  const _tabComponents = [
    <ProjectDetails goToSummary={goToSummary} />,
    <Summary goBack={goToJobProgress} />,
  ];

  const tabs = _tabTitles.map((tab, idx) => ({
    name: tab,
    onClick: onTabClick,
    view: _tabComponents[idx],
  }));

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (!apiInstance.oktaInitialized) return; // When the user navigates directly to a project url

    setActiveTabIdx(0);
    getProjectStages(apiInstance, id || '')
      .then((res) => {
        const projectStagesData = res.responseAsJson;
        dispatch(
          setProject({
            name: projectStagesData.projectName,
            customerName: projectStagesData.oniQuoteStages[0]?.customerName,
            projectId: projectStagesData.projectId,
            region: projectStagesData.oniQuoteStages[0]?.region,
            quoteCount: projectStagesData.oniQuoteStages.length,
            jobCount: projectStagesData.oniOperationsStages.length,
            isCancelled: projectStagesData.isCancelled,
            wellInfo: {
              count: projectStagesData.wellInfo!.count,
              offShore: projectStagesData?.wellInfo!.offShore,
            },
          }),
        );

        dispatch(
          setStages({
            businessDevelopment: projectStagesData.oniQuoteStages,
            technology: projectStagesData.oniTechnologyStages,
            manufacturingData: projectStagesData.oniManufacturingStages,
            operation: projectStagesData.oniOperationsStages,
            milestones: projectStagesData.milestones,
          }),
        );
      })
      .catch((e) => {
        console.log('SalesOrder: Error on getProjectStages', e);
        if (authState?.isAuthenticated && apiInstance.oktaInitialized) {
          if (!e.ok) {
            console.log('SalesOrder: Redirecting to home, invalid response');
            navigate('/'); // Invalid project, redirect to home
          }
        }
      });
  }, [id, dispatch, navigate, authState?.isAuthenticated, apiInstance]);

  return (
    <div className="project-container">
      <aside className="project">
        <span className="back" onClick={() => navigate('/home')}>
          Back
        </span>
      </aside>
      <section className="project">{tabs[activeTabIdx].view}</section>
    </div>
  );
};

export default Project;
