import { CollapsableEntity } from '../../types/CollapsableFilterTypes';

const NonCollapsableFilterPseudoGroup = ({
  filterGroupData,
  listHeader,
}: {
  filterGroupData: Array<CollapsableEntity>;
  listHeader: string;
}) => {
  return (
    <div className="aside-filter-section-group">
      <span className="aside-filter-section-group-header">{listHeader}</span>
      <div className="aside-filter-section-group-content">
        {filterGroupData.map((section, idx) => (
          <div className="aside-filter-section-container" key={section.name + idx}>
            <div className="aside-filter-section-name">{section.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NonCollapsableFilterPseudoGroup;
