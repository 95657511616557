export const enum ProjectStatus {
  operations = 'Operations',
  manufacturing = 'Manufacturing',
  technology = 'Technology',
  initiation = 'Initiation',
  complete = 'Complete',
}

export const enum ProjectType {
  onShore,
  offShore,
}
