import { ApiBase, ApiResponse } from '../apiBase';

export const sortTypes = {
  LAST_MOD_DATE: 'LastModDate',
  CUSTOMER_NAME: 'CustomerName',
};

/**
 * Get home projects data.
 */
export const getProjects = (
  apiInstance: ApiBase,
  amount: number = 5,
  skip: number = 0,
  sortType: string = sortTypes.LAST_MOD_DATE,
  reverseOrder: boolean = false,
  searchValue: string = '',
): Promise<ApiResponse> => {
  return apiInstance.get(
    `Projects?Amount=${amount}&Skip=${skip}&SortType=${sortType}&ReverseOrder=${reverseOrder}${
      searchValue ? `&ProjectSearchValue=${searchValue}` : ''
    }`,
  );
};

/**
 * Get project stages.
 */
export const getProjectStages = (apiInstance: ApiBase, projectId: string): Promise<ApiResponse> => {
  return apiInstance.get(`Projects/Stages?projectId=${projectId}`);
};
