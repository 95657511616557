import React from 'react';
import { IProject } from '../../../models/project';
import { ProjectSubHeaderItem } from '../../../types/ProjectTypes';

// CSS classes here will be added to the subheader item rendered
export const SubHeaderItemType = {
  INFO: { key: 'info', title: '' },
  JOB_STAGE: { key: 'job-stage', title: 'Project Stage' },
};

type SubHeaderTags = {
  [key: string]: { tag: string; className: string };
};

const subHeaderTags: SubHeaderTags = {
  Initation: {
    tag: 'Initation',
    className: 'business-development',
  },
  Technology: { tag: 'Technology', className: 'technology' },
  Manufacturing: { tag: 'Manufacturing', className: 'manufacturing' },
  Operations: { tag: 'Operations', className: 'operations' },
};

interface ProjectDetailsHeaderProps {
  project: IProject;
  subHeaderItems: ProjectSubHeaderItem[];
  stagesComplete: boolean;
  cancelled: boolean;
}

const ProjectDetailsHeader = ({
  project,
  subHeaderItems,
  stagesComplete,
  cancelled,
}: ProjectDetailsHeaderProps) => {
  const jobStageTags = subHeaderItems.find((item) => item.type === SubHeaderItemType.JOB_STAGE.key);

  return (
    <div className="project-details-header">
      <div className="header-info">
        <div className="tags">
          <section id="sub-header-tags">
            {subHeaderItems
              .filter((item) => item.type !== SubHeaderItemType.JOB_STAGE.key)
              .map((item, idx) => (
                <div key={idx} className="sub-item">
                  <div className="header-item">
                    <h5>{item.title}</h5>
                  </div>
                  <div className="value-container">
                    <span className={`${item.type} value`}>{item.value}</span>
                  </div>
                </div>
              ))}
            <div className="sub-item">
              <div className="header-item">
                <h5>{SubHeaderItemType.JOB_STAGE.title}</h5>
              </div>
              <div className="value-container">
                {cancelled ? (
                  <span className="value">Canceled</span>
                ) : jobStageTags?.value.length === 0 ? (
                  <span className={`value`}>{stagesComplete ? 'Complete' : ''}</span>
                ) : (
                  (jobStageTags?.value as string[]).map((val, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <span className="value">{subHeaderTags[val].tag}</span>
                      </React.Fragment>
                    );
                  })[0] ?? '' // Only show the first tag
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsHeader;
