import ProjectCard from './ProjectCard';
import { ProjectItem } from '../../types/ProjectTypes';

import { useEffect, useState } from 'react';
import { ProjectStatus } from '../../constants/projectItem';

import SearchAndSortPanel from './SearchAndSortPanel';
import { useWindowSize } from '../../utils/hooks/useWindowSize';

import { useAppDispatch, useAppSelector } from '../../features/state/hooks';
import { openHideableFilters, toggleHideableFilters } from '../../features/state/slices/homeSlice';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import {
  increaseProjectsShownAmount,
  updateProjectsConfigSkip,
} from '../../features/state/slices/homeSlice';

const ProjectCardsSection = ({
  projectCards,
  showActive,
  onSortChange,
  onSearchChange,
}: {
  projectCards: Array<ProjectItem>;
  showActive: boolean;
  onSortChange: (sortOption: { config: { reverseOrder: any } }) => void;
  onSearchChange: (searchValue: string) => void;
}) => {
  const [showActiveProjects, setShowActiveProjects] = useState(showActive);

  const loading = useAppSelector((state) => state.home.value.loading);

  const loadMoreProjects = () => {
    dispatch(updateProjectsConfigSkip());
    dispatch(increaseProjectsShownAmount());
  };

  const totalProjects = parseInt(
    useAppSelector((state) => state.home.value.summaryHeaderInfo.totalProjects),
  );
  const { projects, hideableFilter } = useAppSelector((state) => state.home.value);

  const { isMobile } = useWindowSize();
  const dispatch = useAppDispatch();
  const sortAndFilterHandler = isMobile ? openHideableFilters : toggleHideableFilters;

  let lastScrollTop = 0;

  useEffect(() => {
    if (isMobile) {
      const onScroll = (e: Event) => {
        if (window.innerHeight + Math.round(window.scrollY) >= document.body.offsetHeight) {
          if (totalProjects > projects.length && !loading) {
            loadMoreProjects();
          }
        }
      };
      window.addEventListener('scroll', onScroll);

      return () => window.removeEventListener('scroll', onScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, projects, totalProjects, loading]);

  const projectCardSection = (
    <div className="project-cards-section">
      {projectCards
        .filter((pc) => (pc.projectStatus !== ProjectStatus.complete) === showActiveProjects)
        .map((projectCard, idx) => (
          <ProjectCard key={projectCard.projectName + idx} {...projectCard} />
        ))}
      {loading && <div className="loader" />}
    </div>
  );
  return (
    <div className="project-cards-section-container">
      {/* TODO: Check how we'll show completed projects */}
      {/* <button
        onClick={() => {
          setShowActiveProjects((activeProjectsShowedState) => !activeProjectsShowedState);
        }}>
        switch
      </button> */}
      <div className="project-cards-section-filter-wrapper">
        <h2 className="project-cards-section-name">
          {showActiveProjects
            ? `Active Projects${
                hideableFilter.selectedFilterName ? `: ${hideableFilter.selectedFilterName}` : ''
              }`
            : 'Completed Projects'}
        </h2>
        <button
          className="sort-and-filters-button"
          onClick={() => dispatch(sortAndFilterHandler())}
        />
        {!isMobile && (
          <SearchAndSortPanel onSearchChange={onSearchChange} onSortChange={onSortChange} />
        )}
      </div>
      {isMobile ? (
        projectCardSection
      ) : (
        <OverlayScrollbarsComponent
          events={{
            scroll(instance, event) {
              //https://stackoverflow.com/questions/67549676/detecting-end-of-scroll-in-a-div
              const scrollingBox = event.target as HTMLElement;
              if (scrollingBox.scrollTop < lastScrollTop) {
                // upscroll
                return;
              }
              lastScrollTop = scrollingBox.scrollTop <= 0 ? 0 : scrollingBox.scrollTop;
              if (scrollingBox.scrollTop + scrollingBox.offsetHeight >= scrollingBox.scrollHeight) {
                if (!loading && totalProjects > projects.length) {
                  loadMoreProjects();
                }
              }
            },
          }}
          defer>
          {projectCardSection}
        </OverlayScrollbarsComponent>
      )}
    </div>
  );
};

export default ProjectCardsSection;
