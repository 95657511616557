import { bingMapsApiKey } from '../../../globalConfig';
import MapPin from '../../../assets/icons/map_pin.svg';
import { useEffect, useState } from 'react';
import { BingMaps } from './BingMaps';

export type PushPin = {
  center: {
    latitude: number;
    longitude: number;
  };
  options: {
    title: string;
  };
};

interface ProjectDetailsMapProps {
  pushPins: PushPin[];
}

export const ProjectDetailsMap = ({ pushPins }: ProjectDetailsMapProps) => {
  const [_pushPins, setPushPins] = useState<PushPin[]>([]);

  useEffect(() => {
    setPushPins(
      pushPins?.map((pin) => ({
        center: pin.center,
        options: {
          title: pin.options.title,
          // icon: MapPin,
        },
      })),
    );
  }, [pushPins]);

  if (process.env.REACT_APP_DEV_MODE)
    return <div style={{ minHeight: 205 }}>Map not available in development.</div>; // Don't display map in local dev, we don't have credentials

  return (
    <div style={{ borderRadius: 7, overflow: 'hidden' }}>
      <BingMaps
        bingMapsApiKey={bingMapsApiKey}
        mapOptions={{
          navigationBarMode: 'square',
          showMapTypeSelector: false,
          showLocateMeButton: true,
          showZoomButtons: false,
        }}
        viewOptions={{
          center: { latitude: 27.98785, longitude: 86.925026 },
        }}
        pushPins={_pushPins}
      />
    </div>
  );
};
