import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { ProjectStageChartItem } from '../../../types/ProjectTypes';
import { useWindowSize } from '../../../utils/hooks/useWindowSize';

interface ChartHeaderProps {
  projectTitle: string;
  chartItems: ProjectStageChartItem[];
  subHeaderComponent: React.ReactElement;
}

const ChartHeader = ({
  projectTitle,
  chartItems: _chartItems,
  subHeaderComponent,
}: ChartHeaderProps) => {
  const [chartItems, setChartItems] = useState<ProjectStageChartItem[]>([]);
  const { isMobile } = useWindowSize();
  const chartSize = isMobile ? 48 : 71;

  useEffect(() => {
    // Animate loading charts on mount of component
    const tempChartItems = JSON.parse(JSON.stringify(_chartItems));
    for (var i = 0; i < tempChartItems.length; i++) {
      tempChartItems[i].completed = 0;
    }
    setChartItems(tempChartItems);
    setTimeout(() => {
      setChartItems(_chartItems);
    }, 150);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_chartItems]);

  return (
    <section className="chart-header">
      <div className="title">
        <span>{projectTitle}</span>
      </div>
      <div className="chart-container">
        {subHeaderComponent}
        <section className="graphs">
          <div className="circular-progress-container">
            {chartItems.map((item, idx) => {
              const percentCompleted = item.percentageCompleted;
              return (
                <div key={idx}>
                  <section>
                    <CircularProgress
                      className="circular-progress"
                      variant="determinate"
                      value={percentCompleted}
                      thickness={4.5}
                      size={chartSize}
                    />
                    <span id="circular-progress-text">
                      {item.total > 0 ? `${percentCompleted}%` : 'N/A'}
                    </span>
                  </section>
                  <span className="chart-title">{item.title}</span>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </section>
  );
};

export default ChartHeader;
