/* eslint-disable no-console */
import { createRef, useEffect } from 'react';
import { loadBingApi, Microsoft } from '../../../utils/bingMapLoader';
import { PushPin } from './ProjectDetailsMap';

interface IMapProps {
  bingMapsApiKey: string;
  mapOptions?: any;
  viewOptions?: any;
  pushPins: PushPin[];
}

export const BingMaps = ({ bingMapsApiKey, mapOptions, viewOptions, pushPins }: IMapProps) => {
  const mapRef = createRef<HTMLDivElement>();

  useEffect(() => {
    loadBingApi(bingMapsApiKey)
      .then(() => {
        initMap();
      })
      .catch((error) => {
        console.error('BingMaps: Error Loading Bing Maps: ', error);
      });
  });

  const removePushpins = (map: any) => {
    if (!map || !map.entities) return;
    for (var i = map.entities.getLength() - 1; i >= 0; i--) {
      var pushpin = map.entities.get(i);
      if (pushpin instanceof Microsoft.Maps.Pushpin) {
        map.entities.removeAt(i);
      }
    }
  };

  const addPushpins = (pushPinsToAdd: any, map: any) => {
    removePushpins(map);
    pushPinsToAdd.forEach((pushPin: any) => {
      if (pushPin === null) {
        return;
      }
      const newPin = new Microsoft.Maps.Pushpin(pushPin.center, pushPin.options);
      map.entities.push(newPin);
    });
  };

  const initMap = () => {
    const map = new Microsoft.Maps.Map(mapRef.current);
    if (mapOptions) {
      map.setOptions(mapOptions);
    }
    if (pushPins.length) {
      const bounds = Microsoft.Maps.LocationRect.fromLocations(
        pushPins.map(
          (pin) => new Microsoft.Maps.Location(pin.center.latitude, pin.center.longitude),
        ),
      );
      map.setView({ bounds: bounds });
    } else if (viewOptions) {
      map.setView(viewOptions);
    }

    if (pushPins) {
      addPushpins(pushPins, map);
    }

    return map;
  };
  return <div ref={mapRef} className="map" />;
};
