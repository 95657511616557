import React from 'react';
import NotificationsList from '../common/NotificationsList';
import ContactsList from '../common/ContactsList';
import { useNavigate, useParams } from 'react-router-dom';
import { ContainerWithAsidePanel } from './ContainerWithAsidePanel';

interface ContainerWithNotificationsProps {
  content: React.ReactElement;
}

const ContainerWithNotifications = ({ content }: ContainerWithNotificationsProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  //TODO replace it later with a real data

  const contacts = [
    {
      firstName: 'Bill',
      lastName: 'Murray',
      phone: '5555555555',
      email: 'bill.murray@halliburton.com',
      departmentName: 'Halliburton Rep',
    },
    {
      firstName: 'Jon',
      lastName: 'Lovitz',
      phone: '5555555555',
      email: 'bill.murray@halliburton.com',
      departmentName: 'Halliburton Sales',
    },
    {
      firstName: 'Gilda',
      lastName: 'Radner',
      phone: '5555555555',
      email: 'bill.murray@halliburton.com',
      departmentName: 'Halliburton Operations',
    },
    {
      firstName: 'Gilda',
      lastName: 'Radner',
      phone: '5555555555',
      email: 'bill.murray@halliburton.com',
      departmentName: 'Halliburton Operations',
    },
  ];

  return (
    <ContainerWithAsidePanel
      content={content}
      asideContent={
        <>
          <ContactsList contacts={contacts} />
          <NotificationsList
            onNotificationClick={(id) => {
              navigate(`/notifications/${id}`);
            }}
            projectId={id}
          />
        </>
      }
      hideAsideOnMobile
    />
  );
};

export default ContainerWithNotifications;
