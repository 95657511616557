interface ContainerWithAsidePanelProps {
  content: React.ReactElement;
  asideContent: React.ReactElement;
  hideAsideOnMobile?: boolean;
}

export const ContainerWithAsidePanel = ({
  content,
  asideContent,
  hideAsideOnMobile,
}: ContainerWithAsidePanelProps) => {
  return (
    <section className="container-with-aside-panel">
      <div className="content">{content}</div>
      <aside className={hideAsideOnMobile ? 'hide-on-mobile' : ''}>{asideContent}</aside>
    </section>
  );
};
