export const routeName = 'product';

const ProductCenter = () => {
  return (
    <div>
      <h1>Product Center</h1>
    </div>
  );
};

export default ProductCenter;
