/* eslint-disable no-console */
import OktaAuth, { AccessToken, AuthState, IDToken } from '@okta/okta-auth-js';
import { apiBaseUrl } from '../globalConfig';

const apiMethods = {
  GET: 'GET',
  POST: 'POST',
};

export const HTTP_STATUS_CODES = {
  OK: 200, // Success
  CREATED: 201, // Resource created successfully
  BAD_REQUEST: 400, // Invalid request
  UNAUTHORIZED: 401, // Unauthorized access
  FORBIDDEN: 403, // Access forbidden
  NOT_FOUND: 404, // Resource not found
  METHOD_NOT_ALLOWED: 405, // Method not allowed for the requested resource
  INTERNAL_SERVER_ERROR: 500, // Internal server error
  SERVICE_UNAVAILABLE: 503, // Service unavailable
};

/**
 * API Base class
 */
export class ApiBase {
  baseUrl: string;
  oktaInitialized: boolean;
  oktaInstance: OktaAuth;
  oktaInstanceInProgress: boolean;
  oktaAuthState: AuthState | null;
  openOktaAuthModal: () => void;
  _callingApiInitializedEvents: { calling: boolean; tokenUsed: string };
  _apiInitializedEvents: Function[];
  headers: { Accept: string; 'Content-Type': string };

  constructor(
    baseUrl: string = apiBaseUrl,
    oktaInstance: OktaAuth,
    oktaInitialized: boolean = false,
    oktaAuthState: AuthState | null,
    openOktaAuthModal: () => void,
  ) {
    this.baseUrl = baseUrl;
    this.oktaInitialized = oktaInitialized;
    this.oktaInstance = oktaInstance;
    this.oktaInstanceInProgress = false;
    this.oktaAuthState = oktaAuthState;
    this.openOktaAuthModal = openOktaAuthModal;
    this._callingApiInitializedEvents = { calling: false, tokenUsed: '' };
    this._apiInitializedEvents = [];
    this.headers = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };
  }

  async getOktaTokens(): Promise<{ accessToken: string; idToken: string }> {
    if (!this.oktaAuthState?.isAuthenticated) {
      console.log('ApiBase: No Okta authenticated state found, open okta auth modal');
      this.openOktaAuthModal();
      return { accessToken: '', idToken: '' };
    }

    const _oktaToken = await this.oktaInstance.tokenManager.get('accessToken');
    const _oktaIdToken = await this.oktaInstance.tokenManager.get('idToken');
    const accessToken = (_oktaToken as AccessToken)?.accessToken ?? '';
    const idToken = (_oktaIdToken as IDToken)?.idToken ?? '';

    if (!accessToken || !idToken) {
      console.log('ApiBase: No Okta tokens found');
    }

    return { accessToken, idToken };
  }

  /**
   * Call an authenticated endpoint
   * @param {string} path - Endpoint to be called e.g. hello to call /hello
   * @param {string?} method - GET/POST/DELETE/PUT. GET by default
   * @param {Object?} optional - Optional parameters
   * @param {string?} optional.headers - Optional param for if needing additional headers besides the default ones that already includes the bearer token
   * @param {string?} optional.parameters - Parameters to send to request, will be parsed to json automatically, if both parameters and jsonParameters are passed, only jsonParameters will be used
   * @param {string?} optional.jsonParameters - Parameters to send to request in a json string, if both parameters and jsonParameters are passed, only jsonParameters will be used
   * @returns {Promise<Response>} response - Response of request
   */
  async callApiWithAuth(
    path: string,
    method: string,
    { headers = this.headers, parameters = null, jsonParameters = null } = {},
  ): Promise<Response | void> {
    console.log('ApiBase: callApiWithAuth', path, method);

    if (!this.oktaInitialized) {
      console.log('ApiBase: No okta initialized');
      return;
    }

    const { idToken } = await this.getOktaTokens();

    if (!idToken) {
      console.log('ApiBase: No API token found');
      this.openOktaAuthModal();
      return;
    }

    var body;
    const requestUrl = this._buildUrl(path);
    if (parameters !== null) body = JSON.stringify(parameters);
    if (jsonParameters !== null) body = jsonParameters;
    let response = await fetch(requestUrl, {
      method,
      headers: {
        ...headers,
        Authorization: `Bearer ${idToken}`,
      },
      body,
    });

    const shouldRefreshToken = response.status === HTTP_STATUS_CODES.UNAUTHORIZED;
    if (shouldRefreshToken) {
      console.log('ApiBase: Token expired, open okta auth modal');
      this.openOktaAuthModal();
    }

    return response;
  }

  /**
   * GET Request to API
   * @param {string} path - API endpoint
   * @param {Object?} headers - Optional custom headers
   * @returns {Promise<ApiResponse>} response - Response
   * @throws {ApiError} apiError - Error with info to match the OniError model from the API
   */
  get = async (path: string, headers = undefined): Promise<ApiResponse> => {
    const response = await this.callApiWithAuth(path, apiMethods.GET, headers);
    return this.processNetworkRequest(response);
  };

  /**
   * POST Request to API
   * @param {string} path - API endpoint
   * @param {string?} parameters - Parameters to send to request, will be parsed to json automatically, if both parameters and jsonParameters are passed, only jsonParameters will be used
   * @param {string?} jsonParameters - Parameters to send to request in a json string, if both parameters and jsonParameters are passed, only jsonParameters will be used
   * @param {Object?} headers - Optional custom headers
   * @returns {Promise<Response>} response - Response
   * @throws {ApiError} apiError - Error with info to match the OniError model from the API
   */
  post = async (
    path: string,
    parameters = null,
    jsonParameters = null,
    headers = undefined,
  ): Promise<ApiResponse> => {
    const response = await this.callApiWithAuth(path, apiMethods.POST, {
      headers,
      parameters,
      jsonParameters,
    });
    return this.processNetworkRequest(response);
  };

  /**
   * Process network request or throw error if API returns an error
   * @param {Response} request - Response from the API
   * @returns {Promise<ApiResponse>} response - Processed API response
   * @throws {ApiError} apiError - Error with info to match the OniError model from the API
   */
  processNetworkRequest = async (response: void | Response): Promise<ApiResponse> => {
    const jsonResponse = await response!.json();
    if (!response!.ok) {
      throw new ApiError({ message: jsonResponse.errorMessage, statusCode: response!.status });
    }
    const apiResponse = new ApiResponse(jsonResponse, response!.status);
    return apiResponse;
  };

  /**
   * Helper function to build the api url to be called
   * @param {string} path - Path to build
   */
  _buildUrl(path: string) {
    return `${this.baseUrl}${path}`;
  }

  /**
   * Add a function to be called when API is initialized
   * @param {() => void} callback - Function to be called when API is initialized
   */
  addApiInitializedEvent(callback: Function) {
    this._apiInitializedEvents.push(callback);
  }

  /**
   * Call all functions that are listening for API initialized event
   */
  async _callApiInitializedEvents() {
    if (this._callingApiInitializedEvents.calling) return;
    this._callingApiInitializedEvents.calling = true;
    this._apiInitializedEvents.forEach((callback) => callback());
    this._callingApiInitializedEvents.calling = false;
  }
}

/**
 * Wrapper class for an API Response
 */
class ApiResponse {
  responseAsJson: any;
  statusCode: any;
  constructor(responseAsJson: any, statusCode: string | number) {
    this.responseAsJson = responseAsJson;
    this.statusCode = statusCode;
  }

  get ok() {
    return this.statusCode === 200;
  }
}

/**
 * Wrapper class for an API Error
 */
class ApiError {
  title: string;
  message: string;
  statusCode: number;
  constructor({ title = 'Error', message = '', statusCode = 500 }) {
    this.title = title;
    this.message = message;
    this.statusCode = statusCode;
  }

  get ok() {
    return this.statusCode === 200;
  }
}

export { ApiResponse, ApiError };
